import React, { useState, useEffect } from 'react';

const TextToSpeech = ({ text }) => {
  const [audioUrl, setAudioUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (text) {
      setAudioUrl(null);  // Clear previous audio
      handleConvertTextToSpeech();
    }
  }, [text]);

  const handleConvertTextToSpeech = async () => {
    if (!text) return;

    setIsLoading(true);
    setError(null); 

    const requestBody = {
      text: text,
      model_id: 'eleven_multilingual_v2',
      seed: '3984967214'
    };

    try {
      const response = await fetch('https://api.elevenlabs.io/v1/text-to-speech/onwK4e9ZLuTAKqWW03F9', {
        method: 'POST',
        headers: {
          'xi-api-key': process.env.REACT_APP_ELEVENLABS_API_KEY, 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch the audio');
      }

      // Handle the response as a Blob if it's audio data
      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioUrl(audioUrl); // Set the audio URL for playback
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while converting text to speech.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="audio-wrapper">
      {error && <p>{error}</p>}

      {audioUrl ? (
        <div>
          <audio controls autoPlay>
            <source src={audioUrl} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
        </div>
      ) : (
        <>{isLoading ? 'Converting text to speech...' : 'Waiting for text input...'}</>
      )}
    </div>
  );
};

export default TextToSpeech;
