// MidJourney.js

import React, { useEffect, useState, useRef } from 'react';
import { useAppContext } from '../context/AppContext';
import ImgPlaceholder from './ImgPlaceholder'; // with import
import appContent from '../data/contentData';
import '../styles/ImgMidJourney.scss';

const MidJourney = ({ imagePrompt, loadedImage }) => {
  const [loading, setLoading] = useState(true);
  const [imageURL, setImageURL] = useState('');
  const { updateDataObject, dataObj, convertImageUrlToBase64 } = useAppContext();
  const [showPlaceholderCallout, setShowPlaceholderCallout] = useState(false);

  const midJourneyApiKey = process.env.REACT_APP_MIDJOURNEY_API_KEY;
  const herokuEndpoint = process.env.REACT_APP_WR_HEROKU_ENDPOINT;

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPlaceholderCallout(true);
    }, 10000); //show placeholder image after 10 seconds
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if(loadedImage) {
      setImageURL(loadedImage);
      setLoading(false);
    } else {
        generateMidJourneyImage();      
    }
  }, [imagePrompt]);

  const generateMidJourneyImage = async () => {
    console.log('generateMidJourneyImage');
    let promptResponseData;
    try {
      setLoading(true);

      var midJourneyParameters = ' Pixar-style, Pixar style --ar 1:1 --fast --no words, text, letters, dates';    
      let midjourneyData = {
          prompt: imagePrompt + midJourneyParameters
      };
      const response = await fetch(`${herokuEndpoint}/https://cl.imagineapi.dev/items/images/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${midJourneyApiKey}`
        },
        body: JSON.stringify(midjourneyData)
      });
      promptResponseData = await response.json();
    } catch (error) {
        console.error('Error generating image:', error);
        throw error;
        setLoading(false);
    }

    var max_intervals = 100;
    var current_interval = 0;
    const intervalId = setInterval(async function () {
      try {
        console.log('Checking image details');
        current_interval++;
        const response = await fetch(`${herokuEndpoint}/https://cl.imagineapi.dev/items/images/${promptResponseData.data.id}`, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${midJourneyApiKey}`,
              'Content-Type': 'application/json'
          }
        })

        const responseData = await response.json();
        if (responseData.data.status === 'completed' || responseData.data.status === 'failed' || (current_interval >= max_intervals) ) {
          // stop repeating
          clearInterval(intervalId);
          console.log(responseData);
          if(responseData.data.upscaled_urls) {
            const imageURLForConversion = `${herokuEndpoint}/${responseData.data.upscaled_urls[0]}`;
            const imageURL = responseData.data.upscaled_urls[0];
            convertImageUrlToBase64(imageURLForConversion).then(convertedImage => {
              updateDataObject({
                MadLibID: dataObj.MadLibID,
                ImageURL_MidJourney: convertedImage
              });
              console.log('save MidJourney image');
              console.log('MadLibID: ' + dataObj.MadLibID);
              console.log(convertedImage);
              setImageURL(convertedImage);
              setLoading(false);
            });
          }

        } else {
          console.log("Image is not finished generation. Status: ", responseData.data.status)
        }
      } catch (error) {
        console.error('Error getting updates', error);
        throw error;
      }
    }, 2000 /* every 2 seconds */);


  }; //end of generateMidJourneyImage

  return (
    <div>
      <h4>MidJourney</h4>
      {loading || imageURL === 'null' ? (
        <div className="mid-journey-preloader">
          <div className={`placeholder-callout ${showPlaceholderCallout ? 'fade-in' : ''}`}>
            {appContent.pages.home.results.placeholder.text}
            <a href={appContent.pages.home.results.placeholder.button.path}>{appContent.pages.home.results.placeholder.button.title}</a>
          </div>
          <ImgPlaceholder />
        </div>
      ) : (
        <img src={imageURL} alt="Image generated by MidJourney" />
      )}
    </div>
  );
};

export default MidJourney;
