import React, { useState, useRef } from 'react';
import { useAppContext } from '../context/AppContext';
import appContent from '../data/contentData';
import '../styles/madLibForm.scss';

const MadLibForm = ({ generatedMadLib, onCompleteMadLib }) => {
  const [answers, setAnswers] = useState({});
  const [completedMadLib, setCompletedMadLib] = useState('');
  const { updateDataObject, dataObj, replaceWords } = useAppContext();
  const formRef = useRef(null);

  const madLibTitle = generatedMadLib.split('</h2>')[0].replace(/(<([^>]+)>)/gi, '');

  const parseFields = () => {
    const regExp = /\[.*?\]/g;
    const matches = generatedMadLib.match(regExp) || [];
    return matches.map((field, index) => {
      const [label, example] = field.replace('[', '').replace(']', '').split('|');
      return { label, example, id: `${label}${index}` };
    });
  };

  const fields = parseFields();

  const handleInputChange = (id, value) => {
    setAnswers(prevAnswers => ({ ...prevAnswers, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Build the array of answers in the correct order
    const orderedAnswers = fields.map(field => answers[field.id]);

    try {
      const result = await completeMadLib(orderedAnswers);

      setAnswers({});

      // Directly reset the form fields
      if (formRef.current) {
        formRef.current.reset();
      }
      
      // Pass the result directly to onCompleteMadLib
      onCompleteMadLib(result);
      updateDataObject({
        MadLibID: dataObj.MadLibID,
        MadLib: generatedMadLib,
        UserSubmission: orderedAnswers,
        MadLibCompleted: result
      });
      const element = document.querySelector(`#alert-banner`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } catch (error) {
      console.error('Error:', error);
    }

  };

  const completeMadLib = async (orderedAnswers) => {
    const openAIEngine = process.env.REACT_APP_OPENAI_ENGINE;
    const openAIapiKey = process.env.REACT_APP_OPENAI_API_KEY;
    const filledInMadLib = replaceWords(generatedMadLib, orderedAnswers.join(', '));
    return filledInMadLib;
    const prompt = `
      Your task is to correct any and all grammatical errors in the text provided below. Focus exclusively on adjusting verb tense, making nouns singular or plural as needed, and correcting articles ('a' to 'an', 'a' to 'the', or vice versa, where grammatically correct). It's crucial to avoid changing the original words, adding new words, or inserting any additional formatting tags. Your goal is to make minimal yet precise grammatical corrections to ensure the sentences are grammatically correct while preserving its original humor and content. Please return the corrected sentence exactly in the format it was given.

      Here are examples to illustrate the type of corrections you should make, noting that these are hypothetical and not direct content:

      Original: 'She find 3 apple for make pie.'
      Corrected: 'She finds 3 apples for making pie.'

      Original: 'He run fastly to catch a busses.'
      Corrected: 'He runs fast to catch a bus.'

      Original: 'They had dance like dinosaur.'
      Corrected: 'They had danced like dinosaurs.'

      Original: 'This winter, we planned on imagining a noisiest snowman, but rhinos got the better of us!'
      Corrected: 'This winter, we planned on imagining the noisiest snowman, but rhinos got the better of us!'

      Remember, the essence and original format of the provided text should remain unchanged. Only make grammatical corrections where necessary, focusing on verb tense, noun number, and article usage.
    `
    try {
      const response = await fetch(`https://api.openai.com/v1/engines/${openAIEngine}/completions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${openAIapiKey}`
        },
        body: JSON.stringify({
          prompt,
          max_tokens: 400
        })
      });

      const data = await response.json();
      const result = data.choices[0].text.trim();
      return result;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="mad-lib-form">
      <div className="container">
      <h2 className="sub-headline">{appContent.pages.home.form.headline}</h2>
        <h3>{madLibTitle}</h3>
        <form onSubmit={handleSubmit} ref={formRef}>
          {fields.map(field => (
            <div key={field.id}>
              <input
                type="text"
                id={field.id}
                onChange={(e) => handleInputChange(field.id, e.target.value)}
                required
              />
              <label>
                <span>{field.label}</span> (<em>i.e. {field.example}</em>)
              </label>
            </div>
          ))}
          <input type="submit" value="Generate my mad-lib!" />
        </form>
      </div>
    </div>
  );
};

export default MadLibForm;