import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import MadLibResultImages from './MadLibResultImages';
import appContent from '../data/contentData'; // Adjust the path as necessary
import TextToSpeech from './ElevenLabsTextToSpeech';
import '../styles/MadLibResult.scss';

function stripHTML(str) {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return doc.body.textContent || doc.body.innerText;
}

const MadLibResult = ({ loadedMadLib, completedMadLib, madLibImages }) => {
  const data = completedMadLib;
  const textWithSpace = data.replace('</h2><p>','.</h2> <p>');
  const plainText = stripHTML(textWithSpace);
  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(data)
  });
  return (
    <div className="mad-lib-result">
      <div className="mad-lib-result-text">
        <div className="container">
          <div
            dangerouslySetInnerHTML={sanitizedData()}
          />
        <TextToSpeech text={plainText} />
      </div>
      </div>
        <MadLibResultImages loadedMadLib={loadedMadLib} completedMadLib={sanitizedData()} madLibImages={madLibImages} />
    </div>
  );
};

export default MadLibResult;