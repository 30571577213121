// Flux.js

import React, { useEffect, useState, useRef } from 'react';
import { useAppContext } from '../context/AppContext';
import ImgPlaceholder from './ImgPlaceholder'; // with import

const Flux = ({ imagePrompt, loadedImage }) => {
  const [loading, setLoading] = useState(true);
  const [imageURL, setImageURL] = useState('');
  const { updateDataObject, dataObj, convertImageUrlToBase64 } = useAppContext();

  const apiKey = process.env.REACT_APP_FLUX_API_KEY;
  const herokuEndpoint = process.env.REACT_APP_WR_HEROKU_ENDPOINT;

  useEffect(() => {
    if(loadedImage) {
      setImageURL(loadedImage);
      setLoading(false);
    } else {
        generateFluxImage();      
    }
  }, [imagePrompt]);

    const generateFluxImage = async () => {
        try {
        setLoading(true);
        const flux_options = {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'x-key': apiKey,
            },
            body: JSON.stringify({
            prompt: imagePrompt,
            height: 1024,
            width: 1024,
            }),
        };
    
        const response = await fetch(`https://api.bfl.ml/v1/flux-pro-1.1`, flux_options);
        if (!response.ok) {
            throw new Error(`Non-200 response: ${await response.text()}`);
        }
    
        const data = await response.json();
        const requestId = data.id;     
        console.log(`Request ID: ${requestId}`);
        const pollResult = async (id) => {
            while (true) {
            await new Promise(resolve => setTimeout(resolve, 500)); // Wait for 0.5 seconds before polling
    
            const resultResponse = await fetch(`https://api.bfl.ml/v1/get_result?id=${id}`, {
                method: 'GET',
                headers: {
                'accept': 'application/json',
                'x-key': apiKey,
                },
            });
    
            if (!resultResponse.ok) {
                throw new Error(`Non-200 response: ${await resultResponse.text()}`);
            }
    
            const resultData = await resultResponse.json();
            const status = resultData.status;
    
            if (status === "Ready") {
                console.log(`Result: ${resultData.result.sample}`);  // Handle the result when ready
                return resultData.result.sample;
            } else {
                console.log(`Status: ${status}`);  // Log the current status
            }
            }
        };
    
        const finalResult = await pollResult(requestId);
        console.log(`Final result: ${finalResult}`);
        const imageURLForConversion = `${herokuEndpoint}/${finalResult}`;
    
        convertImageUrlToBase64(imageURLForConversion).then(convertedImage => {
            updateDataObject({
              MadLibID: dataObj.MadLibID,
              ImageURL_Flux: convertedImage
            });
            console.log('save Flux image');
            console.log('MadLibID: ' + dataObj.MadLibID);
            console.log(convertedImage);
            setImageURL(convertedImage);
            setLoading(false);
          });
    
        } catch (error) {
        console.error('Error:', error);
        setLoading(false);
        }
    };
  
    return (
    <div>
      <h4>Flux</h4>
      {loading ? (
        <ImgPlaceholder />
      ) : (
        <img src={imageURL} alt="Image generated by Flux" />
      )}
    </div>
  );
};

export default Flux;